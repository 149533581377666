import { useCallback, useRef } from 'react';
import { isEqualValue } from '../../v2/utils/isEqualValue';
import { useGetAdditionalPropertyValue } from '../../v2/hooks/useGetAdditionalPropertyValue';
import { useObjectId } from '../../v2/hooks/useObjectId';
import { useObjectTypeId } from '../../v2/hooks/useObjectTypeId';
import { useProperty } from '../../v2/hooks/useProperty';
export const usePropertyEdits = ({
  onChange
}) => {
  const property = useProperty();
  const objectId = useObjectId();
  const objectTypeId = useObjectTypeId();
  const getAdditionalPropertyValue = useGetAdditionalPropertyValue();
  const pendingEditsRef = useRef({});
  const originalValues = useRef({});
  const clearEdits = useCallback(() => {
    pendingEditsRef.current = {};
    originalValues.current = {};
  }, []);
  const getHasPendingEdits = useCallback(() => {
    return Object.keys(pendingEditsRef.current).length > 0;
  }, []);
  const getPendingEdits = useCallback(() => {
    return pendingEditsRef.current;
  }, []);
  const getOriginalValues = useCallback(() => {
    return originalValues.current;
  }, []);
  const wrappedOnChange = useCallback(changes => {
    Object.entries(changes).forEach(([name, value]) => {
      pendingEditsRef.current[name] = value;
      if (!Object.prototype.hasOwnProperty.call(originalValues.current, name)) {
        const originalValue = getAdditionalPropertyValue(name, objectId, objectTypeId);
        originalValues.current[name] = originalValue;
      }
    });
    if (changes[property.name] !== undefined) {
      const hasReturnedToOriginal = isEqualValue(changes[property.name], originalValues.current[property.name], property);
      if (hasReturnedToOriginal) {
        pendingEditsRef.current = {};
      }
    }
    onChange(changes);
  }, [property, onChange, getAdditionalPropertyValue, objectId, objectTypeId]);
  return {
    clearEdits,
    getPendingEdits,
    getOriginalValues,
    getHasPendingEdits,
    onChange: wrappedOnChange
  };
};
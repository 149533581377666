import http from 'conversations-http/clients/http';
export const fetchSpacesAndViewsClient = ({
  spaceId,
  viewId,
  workspaceId
}) => http.get(`conversations-custom-views/v1/spaces-and-views/workspace/${workspaceId}`, {
  query: {
    spaceId,
    viewId
  }
});
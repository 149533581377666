const PLAIN_TEXT = 'PLAIN_TEXT';
export const toEventResponse = latestPreviewResponse => {
  const latestPreviewResults = latestPreviewResponse && latestPreviewResponse.messages && latestPreviewResponse.messages.results[0];
  if (latestPreviewResults.error) {
    return {
      message: latestPreviewResults.message,
      messageId: latestPreviewResults.id,
      sections: [{
        id: `${latestPreviewResults.id}`,
        text: latestPreviewResults.response,
        type: PLAIN_TEXT
      }],
      error: latestPreviewResults.error,
      timestamp: latestPreviewResults.created_at
    };
  } else {
    return {
      message: latestPreviewResults.message,
      sections: latestPreviewResults.sections,
      messageId: latestPreviewResults.id,
      threadId: latestPreviewResults.thread_id,
      timestamp: latestPreviewResults.created_at
    };
  }
};
import styled from 'styled-components';
const animationConfig = 'cubic-bezier(0.76, 0, 0.24, 1)';
export const StyledAIBaseLoadingButtonAnimation = styled.span.withConfig({
  displayName: "AILoadingButtonAnimations__StyledAIBaseLoadingButtonAnimation",
  componentId: "sc-1c2l2et-0"
})([".ai-loading-children{margin-left:0.5em;display:inline-block;vertical-align:bottom;&.start{animation:0.7s forwards labelAnimForward ", ";}&.end{animation:0.7s forwards labelAnimBackward ", ";}&.loading{opacity:0;}}.ai-icons{display:inline-block;position:relative;bottom:1px;line-height:1em;max-height:1em;vertical-align:middle;&.start{animation:0.7s forwards iconsAnimForward ", ";}&.end{animation:0.7s forwards iconsAnimBackward ", ";}&.loading{left:calc(50% - 7px);}}.ai-loading-icon{position:relative;opacity:", ";&.start{animation:0.7s forwards loadingIconStartTransition ", ";}&.end{animation:0.7s forwards loadingIconEndTransition ", ";}&.loading{animation:5s forwards sparkleAnim ", " infinite;}}.ai-refresh-icon{opacity:", ";position:absolute;&.start{animation:0.7s forwards refreshIconStartTransition ", ";}&.end{animation:0.7s forwards refreshIconEndTransition ", ";}&.loading{opacity:0;}}@keyframes iconsAnimForward{0%{left:0;}100%{left:calc(50% - 7px);}}@keyframes iconsAnimBackward{0%{left:calc(50% - 7px);}100%{left:0;}}@keyframes loadingIconStartTransition{0%{opacity:", ";}100%{opacity:1;}}@keyframes loadingIconEndTransition{0%{opacity:1;}100%{opacity:0;}}@keyframes refreshIconStartTransition{0%{opacity:", ";}100%{opacity:0;}}@keyframes refreshIconEndTransition{0%{opacity:", ";}100%{opacity:1;}}@keyframes sparkleAnim{0%{transform:rotate(0deg);left:calc(50% - 7px);opacity:1;}25%{transform:rotate(90deg);opacity:1;}50%{transform:rotate(180deg);opacity:1;}75%{transform:rotate(270deg);}100%{transform:rotate(360deg);left:calc(50% - 7px);opacity:1;}}@keyframes labelAnimForward{0%{transform:translateX(0px);opacity:1;}100%{transform:translateX(30px);opacity:0;}}@keyframes labelAnimBackward{0%{transform:translateX(30px);opacity:0;}100%{transform:translateX(0px);opacity:1;}}"], animationConfig, animationConfig, animationConfig, animationConfig, ({
  isRegenerate
}) => isRegenerate ? 0 : 1, animationConfig, animationConfig, animationConfig, ({
  isRegenerate
}) => isRegenerate ? 1 : 0, animationConfig, animationConfig, ({
  isRegenerate
}) => isRegenerate ? 0 : 1, ({
  isRegenerate
}) => isRegenerate ? 1 : 0, ({
  isRegenerate
}) => isRegenerate ? 0 : 1);
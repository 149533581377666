import { registerMutation, useMutation } from 'data-fetching-client';
import { createSpaceClient } from '../clients/createSpaceClient';
import { fetchSpaces } from '../operations/fetchSpaces';
const CREATE_SPACE = 'createSpace';
const createSpaceMutation = registerMutation({
  fieldName: CREATE_SPACE,
  fetcher: createSpaceClient
});
export function useCreateSpace() {
  return useMutation(createSpaceMutation, {
    //Refetch spaces after creating a space
    refetchQueries: [fetchSpaces]
  });
}
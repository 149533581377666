import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getSize } from '../operators/getSize';
// Default props
const DEFAULT_HEIGHT = '100%';
const DEFAULT_WIDTH = '100%';
const SkeletonContainer = styled.div.withConfig({
  displayName: "SkeletonContainer",
  componentId: "qz3ow3-0"
})(["height:", ";width:", ";min-width:", ";background:", ";margin:", ";padding:", ";"], ({
  height = DEFAULT_HEIGHT
}) => getSize(height), ({
  width = DEFAULT_WIDTH
}) => getSize(width), ({
  width = DEFAULT_WIDTH
}) => getSize(width), ({
  background
}) => background, ({
  margin
}) => getSize(margin), ({
  padding
}) => getSize(padding));
SkeletonContainer.displayName = 'SkeletonContainer';
SkeletonContainer.propTypes = {
  background: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
export default SkeletonContainer;
import { registerMutation, useDataFetchingClient, useMutation } from 'data-fetching-client';
import { editSpaceClient } from '../clients/editSpaceClient';
import { SPACES, fetchSpaces } from '../operations/fetchSpaces';
import { SPACE, fetchSpace } from './useSpace';
const EDIT_SPACE = 'editSpace';
const editSpaceMutation = registerMutation({
  fieldName: EDIT_SPACE,
  fetcher: editSpaceClient
});
export function useEditSpace() {
  const client = useDataFetchingClient();
  return useMutation(editSpaceMutation, {
    //Refetch spaces after editing a space
    onCompleted: data => {
      client.cache.evict({
        fieldName: SPACES
      });
      client.cache.evict({
        fieldName: SPACE,
        args: {
          spaceId: data.editSpace.space.id
        }
      });
    },
    refetchQueries: [fetchSpace, fetchSpaces]
  });
}
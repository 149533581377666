import { useQuery } from 'data-fetching-client';
import { useMemo } from 'react';
import { GET_VALID_CURRENCIES } from '../rest/queries';
import { useHasAllSupportedCurrenciesGate, useHasExpandedMultiCurrencyGate } from './useAuthGate';
export function usePortalCurrencies() {
  const {
    data,
    loading
  } = useQuery(GET_VALID_CURRENCIES);
  const hasAllSupportedCurrenciesGate = useHasAllSupportedCurrenciesGate();
  const hasExpandedMultiCurrencyGate = useHasExpandedMultiCurrencyGate();
  return useMemo(() => {
    var _currencyList$find;
    const portalCurrencies = data !== null && data !== void 0 && data.currencyData && Array.isArray(data.currencyData) ? data.currencyData : [];
    const supportedCurrencies = portalCurrencies.filter(currency => currency.isProcessorSupportedCurrency);
    const currencyList = hasAllSupportedCurrenciesGate || hasExpandedMultiCurrencyGate ? portalCurrencies : supportedCurrencies;
    return {
      loading,
      supportedCurrencies,
      defaultCurrency: (_currencyList$find = currencyList.find(currency => currency.isHomeCurrency && currency.isActivePortalCurrency)) === null || _currencyList$find === void 0 ? void 0 : _currencyList$find.currencyCode,
      allCurrencies: portalCurrencies
    };
  }, [data === null || data === void 0 ? void 0 : data.currencyData, hasAllSupportedCurrenciesGate, hasExpandedMultiCurrencyGate, loading]);
}
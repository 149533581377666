export const QUOTE_SETTINGS = {
  DEFAULT_EXPIRATION_DAYS: 'default-expiration-days',
  ALLOW_PAY_BEFORE_ESIGN: 'allow-pay-before-esign',
  DEFAULT_TOTAL_PREFERENCE: 'default-total-preference',
  DISPLAY_TCV_ON_QUOTE: 'display-tcv-on-quote',
  QUOTE_TOTAL_DISPLAY_PREFERENCE: 'quote-total-display-preference',
  ALLOW_GDPR_QUOTE_DELETES: 'allow-gdpr-quote-deletes-upon-contact-delete',
  DEFAULT_FROM_EMAIL: 'quote-sender-default-from-email-address',
  ALLOW_OTHER_EMAILS: 'quote-sender-allow-other-email-addresses',
  ESIGN_VERIFICATION_METHOD: 'esign-verification-method'
};
export const QUOTE_TOTAL_DISPLAY_PREFERENCE_KEYS = {
  HS_TCV: 'hs_tcv',
  HS_QUOTE_AMOUNT: 'hs_quote_amount'
};
export const ESIGN_VERIFICATION_METHOD = {
  EMAIL: 'email',
  NONE: 'none'
};
import { registerQuery, useQuery } from 'data-fetching-client';
import { fetchSpaceClient } from '../clients/fetchSpaceClient';
import { useEditSpace } from './useEditSpace';
export const SPACE = 'space';
export const fetchSpace = registerQuery({
  fieldName: SPACE,
  fetcher: fetchSpaceClient
});
export const useSpace = ({
  spaceId
}) => {
  var _data$space$entityNam, _data$space$space;
  const {
    data,
    error,
    networkStatus
  } = useQuery(fetchSpace, {
    fetchPolicy: 'cache-and-network',
    variables: {
      spaceId
    }
  });

  //Prepare the mutation to edit a space
  const [editSpace, {
    loading: isEditSpaceLoading
  }] = useEditSpace();
  return {
    entityNames: (_data$space$entityNam = data === null || data === void 0 ? void 0 : data.space.entityNames) !== null && _data$space$entityNam !== void 0 ? _data$space$entityNam : null,
    error,
    networkStatus,
    space: (_data$space$space = data === null || data === void 0 ? void 0 : data.space.space) !== null && _data$space$space !== void 0 ? _data$space$space : null,
    editSpace,
    isEditSpaceLoading
  };
};
import { COMMUNICATION_SUMMARY, OBJECT_ENGAGEMENTS_SUMMARY, COMPANY_NEWS, COMPANY_TECH, OBJECT_SUMMARY, OBJECT_UPDATE, TASK_CREATE, SUMMARIZE_ACTIVITY_FEED, WORKFLOW_CREATE, WORKFLOW_GENERATION, REPORTING_INSIGHTS_COPILOT_GENERATION, DASHBOARD_GENERATION, MARKETPLACE } from '../constants/CopilotInAppActionTypes';
import { objectSummary } from './parsers/objectSummary';
import { objectUpdate } from './parsers/objectUpdate';
import { companyNews } from './parsers/companyNews';
import { companyTech } from './parsers/companyTech';
import { communicationSummary } from './parsers/communicationSummary';
import { objectEngagementsSummary } from './parsers/objectEngagementsSummary';
import { taskCreate } from './parsers/taskCreate';
import { summarizeActivityFeed } from './parsers/summarizeActivityFeed';
import { workflowCreate } from './parsers/workflowCreate';
import { workflowGeneration } from './parsers/workflowGeneration';
import { reportingInsightsCopilotGeneration } from './parsers/reportingInsightsCopilotGeneration';
import { dashboardGeneration } from './parsers/dashboardGeneration';
import { marketplace } from './parsers/marketplace';
const buildMessageAction = (partialMessageAction, inAppMessageAction) => {
  return Object.assign({
    form: null,
    format: null,
    link: null,
    module: null,
    openai_config: null,
    title: '',
    raw_action: inAppMessageAction
  }, partialMessageAction);
};

// remove when fully moved parser to app
export const parseInAppCopilotAction = inAppMessageAction => {
  let parsedAction = undefined;
  switch (inAppMessageAction.intent) {
    case OBJECT_SUMMARY:
      parsedAction = objectSummary(inAppMessageAction);
      break;
    case OBJECT_UPDATE:
      parsedAction = objectUpdate(inAppMessageAction);
      break;
    case COMPANY_NEWS:
      parsedAction = companyNews(inAppMessageAction);
      break;
    case COMPANY_TECH:
      parsedAction = companyTech(inAppMessageAction);
      break;
    case COMMUNICATION_SUMMARY:
      parsedAction = communicationSummary(inAppMessageAction);
      break;
    case DASHBOARD_GENERATION:
      parsedAction = dashboardGeneration(inAppMessageAction);
      break;
    case OBJECT_ENGAGEMENTS_SUMMARY:
      parsedAction = objectEngagementsSummary(inAppMessageAction);
      break;
    case TASK_CREATE:
      parsedAction = taskCreate(inAppMessageAction);
      break;
    case SUMMARIZE_ACTIVITY_FEED:
      parsedAction = summarizeActivityFeed(inAppMessageAction);
      break;
    case WORKFLOW_CREATE:
      parsedAction = workflowCreate(inAppMessageAction);
      break;
    case WORKFLOW_GENERATION:
      parsedAction = workflowGeneration(inAppMessageAction);
      break;
    case REPORTING_INSIGHTS_COPILOT_GENERATION:
      parsedAction = reportingInsightsCopilotGeneration(inAppMessageAction);
      break;
    case MARKETPLACE:
      parsedAction = marketplace(inAppMessageAction);
      break;
    default:
      parsedAction = undefined;
  }
  if (parsedAction === undefined) {
    return undefined;
  }
  return buildMessageAction(parsedAction, inAppMessageAction);
};
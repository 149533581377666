export const AVAILABILITY_MANAGEMENT_WRITER = 'availability-management-write';
export const BET_DOMAIN_CNTROLS_ACCESS = 'bet-domain-controls-access';
export const BET_RAISE_IQL_ACCESS = 'bet-raise-iql-access';
export const CONNECTED_EMAIL_DATA_ACCESS = 'connected-email-data-access';
export const CONVERSATIONS_ADMIN = 'conversations-admin';
export const CUSTOM_VIEWS_EDIT = 'custom-views-edit';
export const FIELD_LEVEL_PERMISSIONS_ACCESS = 'field-level-permissions-access';
export const SLA_WRITE = 'sla-write';
export const SUPER_ADMIN = 'super-admin';
export const ZORSE_DASHBOARD_ACCESS = 'zorse-dashboard-access';
export const MESSAGING_ACCESS = 'messaging-access';
export const MESSAGING_TRUSTED = 'messaging-trusted';
export const CALLING_ACCESS = 'calling-access';
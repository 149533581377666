/* hs-eslint ignored failing-rules */
/* eslint-disable hubspot-dev/prefer-relative-imports */

// gimme: conversations-inbox-ui 06/10/2020 24a2e66917cc934433dd111db7e06fd02943ca6a
'use es6';

import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import getPortalId from '../../common/selectors/getPortalId';
import { threadHistoryFailed } from '../../thread-histories/selectors/threadHistoryFailed';
import { fetchAgentHistory } from 'conversations-thread-view/thread-histories/actions/fetchAgentHistory';
import { getMergedMessagesList } from '../selectors/getMergedMessagesList';
import UnifiedThreadHistory from './UnifiedThreadHistory';
import { getFocusedThreadOriginalGenericChannelId } from '../../thread-details/selectors/getFocusedThreadOriginalGenericChannelId';
import { getCurrentVid } from '../../crm-integration/selectors/getCurrentVid';
import { getIncomingThreadId } from '../../performance-metrics/selectors/getIncomingThreadId';
import { getAsyncThreadHistory } from '../../thread-histories/selectors/getAsyncThreadHistory';
import { getUserId } from 'conversations-thread-data/auth/public/selectors';
import { InboxConfig } from '../configs/InboxConfig';
import { provideThreadView } from '../../thread-view/decorators/provideThreadView';
const mapDispatchToProps = {
  fetchAgentHistory
};
const mapStateToProps = (state, props) => {
  const {
    allowContactPromotions,
    threadId,
    historyConfig
  } = props;
  return {
    allowContactPromotions,
    asyncThreadHistory: getAsyncThreadHistory(state, {
      threadId
    }),
    currentUserId: getUserId(state),
    incomingThreadId: getIncomingThreadId(state),
    messagesList: getMergedMessagesList(state, {
      threadId,
      historyConfig
    }),
    portalId: getPortalId(state),
    threadHistoryRequestFailed: threadHistoryFailed(state, {
      threadId
    }),
    genericChannelId: getFocusedThreadOriginalGenericChannelId(state),
    vid: getCurrentVid(state)
  };
};
const ConnectedUnifiedThreadHistory = provideThreadView(connect(mapStateToProps, mapDispatchToProps)(UnifiedThreadHistory));
ConnectedUnifiedThreadHistory.propTypes = {
  allowContactPromotions: PropTypes.bool,
  historyConfig: PropTypes.object.isRequired
};

/* eslint-disable-next-line hubspot-dev/no-default-props-assignment */
ConnectedUnifiedThreadHistory.defaultProps = {
  historyConfig: InboxConfig
};
ConnectedUnifiedThreadHistory.displayName = 'ConnectedUnifiedThreadHistory';
export default ConnectedUnifiedThreadHistory;
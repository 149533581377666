import http from 'hub-http/clients/apiClient';
const getFilterGroups = quoteId => [{
  filters: [{
    property: 'associations.quote',
    operator: 'EQ',
    value: String(quoteId)
  }]
}];
export const getAssociatedPaymentSchedules = (quoteId, portalId) => http.post('crm-search/search', {
  data: {
    count: 1,
    filterGroups: getFilterGroups(quoteId),
    objectTypeId: '0-106',
    offset: 0,
    portalId,
    query: '',
    requestOptions: {},
    sorts: []
  }
}).then(response => response.results);
export const getAssociatedInvoices = (quoteId, portalId) => http.post('crm-search/search', {
  data: {
    count: 1,
    filterGroups: getFilterGroups(quoteId),
    objectTypeId: '0-53',
    offset: 0,
    portalId,
    query: '',
    requestOptions: {},
    sorts: []
  }
}).then(response => response.results);
export const getAssociatedLineItems = (quoteId, portalId) => http.post('crm-search/search', {
  data: {
    count: 100,
    filterGroups: getFilterGroups(quoteId),
    objectTypeId: '0-8',
    offset: 0,
    portalId,
    query: '',
    requestOptions: {
      allPropertiesFetchMode: 'latest_version',
      includeAllProperties: true
    },
    sorts: []
  }
}).then(response => response.results);
export const getAssociatedDiscounts = (quoteId, portalId) => http.post('crm-search/search', {
  data: {
    count: 2,
    filterGroups: getFilterGroups(quoteId),
    objectTypeId: '0-84',
    offset: 0,
    portalId,
    query: '',
    requestOptions: {},
    sorts: []
  }
}).then(response => response.results);
export function crmSearch(data) {
  return http.post('crm-search/search', {
    data
  });
}
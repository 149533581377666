import { isCallingFree } from 'calling-internal-common/utils/CallingSalesPro';
export const getPortalId = userInfo => userInfo.portal.portal_id;
export const getScopes = userInfo => userInfo.user.scopes;
export const getPortalLimits = userInfo => userInfo.portal.limits;
export const getGates = userInfo => userInfo.gates;
export const getUserId = userInfo => userInfo.user.user_id;
export const getUserEmail = userInfo => userInfo.user.email;
export const getIsCallingAdmin = userInfo => getScopes(userInfo).includes('super-admin') || getScopes(userInfo).includes('partner-admin');
export const getHasCallingMinutes = userInfo => {
  const callingMinutesPerPortal = getPortalLimits(userInfo)['calling-minutes-per-portal'];
  return callingMinutesPerPortal ? callingMinutesPerPortal > 0 : false;
};
export const getUserHasPaidSeat = userInfo => !isCallingFree(userInfo.user.scopes);
export const getUserName = userInfo => `${userInfo.user.first_name} ${userInfo.user.last_name}`;
import { getActiveCallDetails } from 'calling-cross-tab-library/appData/operators/widgetDetailsOperators';
import { ConnectionTypes } from 'calling-cross-tab-library/types/ConnectionTypes';
import { CallDirection } from 'calling-cross-tab-library/constants/CallDirection';
export const getRecord = activeCallDetails => activeCallDetails && activeCallDetails.record ? activeCallDetails.record : void 0;
export const getObjectTypeId = record => record && record.objectTypeId ? record.objectTypeId : void 0;
export const getObjectId = record => record && record.objectId ? record.objectId : void 0;
export const getToPhoneNumberObject = activeCallDetails => activeCallDetails && activeCallDetails.toPhoneNumber ? activeCallDetails.toPhoneNumber : void 0;
export const getToPhoneNumber = toPhoneNumberObject => toPhoneNumberObject && toPhoneNumberObject.phoneNumber ? toPhoneNumberObject.phoneNumber : void 0;
export const getFromPhoneNumberObject = activeCallDetails => activeCallDetails && activeCallDetails.fromPhoneNumber ? activeCallDetails.fromPhoneNumber : void 0;
export const getFromPhoneNumber = fromPhoneNumberObject => fromPhoneNumberObject && fromPhoneNumberObject.phoneNumber ? fromPhoneNumberObject.phoneNumber : void 0;
export const getCallDirection = widgetDetails => {
  const activeCallDetails = getActiveCallDetails(widgetDetails);
  return activeCallDetails && activeCallDetails.direction;
};
export const getIsInboundCall = widgetDetails => {
  const activeCallDetails = getActiveCallDetails(widgetDetails);
  return (activeCallDetails && activeCallDetails.direction) === CallDirection.INBOUND;
};
export const getIsOutboundCall = widgetDetails => {
  const activeCallDetails = getActiveCallDetails(widgetDetails);
  return (activeCallDetails && activeCallDetails.direction) === CallDirection.OUTBOUND;
};
export const getIsTabCall = widgetDetails => {
  const activeCallDetails = getActiveCallDetails(widgetDetails);
  return (activeCallDetails && activeCallDetails.connectionType) === ConnectionTypes.STANDALONE_CALLING_APP;
};
export const getIsWidgetCall = widgetDetails => {
  const activeCallDetails = getActiveCallDetails(widgetDetails);
  return (activeCallDetails && activeCallDetails.connectionType) === ConnectionTypes.EXTERNAL;
};
export const getHasConnectionType = widgetDetails => {
  const activeCallDetails = getActiveCallDetails(widgetDetails);
  return activeCallDetails && !!activeCallDetails.connectionType;
};
export const getCallId = widgetDetails => {
  const activeCallDetails = getActiveCallDetails(widgetDetails);
  return activeCallDetails && activeCallDetails.callId;
};
export const getContactName = widgetDetails => {
  const activeCallDetails = getActiveCallDetails(widgetDetails);
  return activeCallDetails && activeCallDetails.contactName;
};
export const getFirstAssociatedCompanyName = widgetDetails => {
  const activeCallDetails = getActiveCallDetails(widgetDetails);
  return activeCallDetails && activeCallDetails.firstAssociatedCompanyName;
};
export const getIsRecordingEnabled = widgetDetails => {
  return widgetDetails.isRecordingEnabled;
};
import { gate } from 'hub-http/gates';

// scopes
export const INVOICE_CREATE_SCOPE = 'invoices-write';
export const SUBSCRIPTIONS_WRITE_SCOPE = 'subscriptions-write';
export const CRM_EDIT_ALL_DEALS_SCOPE = 'crm-edit-all-deals';
export const CRM_EDIT_TEAM_OWNED_DEALS = 'crm-edit-team-owned-deals';
export const CRM_EDIT_UNASSIGNED_DEALS = 'crm-edit-unassigned-deals';
export const CRM_EDIT_OWNED_DEALS = 'crm-edit-owned-deals';

// gates
export const SEAMLESS_SENDING_GATE = gate('CommerceGrowth:SeamlessSending');
export const QUOTE_APPROVALS_GATE = gate('ProductApprovals:Quotes');
export const CPQ_QUOTES_GATE = gate('commerce:cpq:bse:CPQQuote');
export const PENDING_QUOTES_VIEW_GATE = gate('Commerce:ShowView:QuotesToApprove');
export const PROTOTYPE_COVER_LETTER_GATE = gate('commerce:cpq:bse:CoverLetter');
export const PROTOTYPE_COVER_LETTER_GATE_INTERNAL = gate('commerce:cpq:bse:internal:CoverLetter');
export function hasSomeEditScopes(scopes) {
  return scopes.has(CRM_EDIT_ALL_DEALS_SCOPE) || scopes.has(CRM_EDIT_TEAM_OWNED_DEALS) || scopes.has(CRM_EDIT_UNASSIGNED_DEALS) || scopes.has(CRM_EDIT_OWNED_DEALS);
}
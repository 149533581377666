import http from 'hub-http/clients/apiClient';
import { chirpClient } from '../../shared/utils/chirp-client';
import { getRoutableTeamsRpc } from '../../../__generated__/chirp/com/hubspot/cv/assignments/rpc/RoutableTeamsService';
export const fetchRoutingTeams = inboxId => http.get('messages/v2/users/teams/hierarchy', {
  query: {
    inboxId
  }
});
export const fetchRoutingTeamsRpc = ({
  objectTypeId,
  routingSource,
  workspaceId
}) => chirpClient.call(getRoutableTeamsRpc, {
  request: {
    inboxId: workspaceId || undefined,
    objectTypeId,
    routingSource: routingSource || undefined
  }
});
export const fetchTeams = () => http.get('app-users/v1/teams', {
  query: {
    includeHierarchy: true
  }
});
export const fetchTeamsByIds = ids => http.put('app-users/v1/teams/bulk-get', {
  data: ids
});
import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["limit", "sortDirection"];
import http from 'conversations-http/clients/http';
export const fetchSpamViewMembersService = _ref => {
  let {
      limit = 100,
      sortDirection = 'DESC'
    } = _ref,
    rest = _objectWithoutPropertiesLoose(_ref, _excluded);
  return http.put('threadlists/v1/members/page/spam-view-members-for-helpdesk', {
    data: Object.assign({
      limit,
      sortDirection
    }, rest)
  });
};
import debounce from 'hs-lodash/debounce';
import { useState, useEffect, useCallback } from 'react';
import { saveDraft } from '../local-storage/saveDraft';
import { makeDraftKeyFromId } from '../operators/makeDraftKeyFromId';
import { clearDraft } from '../local-storage/clearDraft';
import { Draft } from '../records/Draft';
import { restoreDraft } from '../local-storage/restoreDraft';
import { savePersistedDraft } from '../persisted/saveDraft';
import { restorePersistedDraft } from '../persisted/restoreDraft';
import { clearPersistedDraft } from '../persisted/clearDraft';
import { useDraftPurger } from './useDraftPurger';
import { DRAFT_TTL } from '../constants/draftConstants';
// @ts-expect-error module not typed
import { usePrevious } from './usePrevious';
export function useDraft({
  localStorageKeyPrefix,
  currentVersion,
  deserialize,
  serialize,
  id,
  saveTimeout = 1000,
  emptyChecker = () => false,
  isUngatedForPersistedDrafts = false
}) {
  const restoreFunc = isUngatedForPersistedDrafts ? restorePersistedDraft : restoreDraft;
  const saveFunc = isUngatedForPersistedDrafts ? savePersistedDraft : saveDraft;
  const clearFunc = isUngatedForPersistedDrafts ? clearPersistedDraft : clearDraft;
  useDraftPurger({
    localStorageKeyPrefix,
    currentVersion,
    deserializeData: deserialize,
    emptyChecker,
    ageLimitInMs: DRAFT_TTL
  });
  const previousId = usePrevious(id);
  const draftKey = makeDraftKeyFromId(id, localStorageKeyPrefix);
  const [draft, setDraft] = useState(() => {
    return restoreFunc(draftKey, deserialize);
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(debounce(dataToSave => {
    if (id && !id.includes('null')) {
      const draftToSave = new Draft({
        id,
        version: currentVersion,
        data: dataToSave
      });
      saveFunc({
        draft: draftToSave,
        serializeData: serialize,
        deserializeData: deserialize,
        localStorageKeyPrefix,
        emptyChecker,
        currentVersion
      });
      setDraft(draftToSave);
    }
  }, saveTimeout), [saveTimeout, id]);
  useEffect(() => {
    debouncedSave.flush();
    return debouncedSave.flush;
  }, [debouncedSave]);
  useEffect(() => {
    if (previousId !== id) {
      const latestDraftKey = makeDraftKeyFromId(id, localStorageKeyPrefix);
      const latestRestoredDraft = restoreFunc(latestDraftKey, deserialize);
      setDraft(latestRestoredDraft);
    }
  }, [previousId, id, setDraft, deserialize, localStorageKeyPrefix, restoreFunc]);
  const clear = useCallback(() => {
    debouncedSave.cancel();
    clearFunc(draftKey);
    setDraft(null);
  }, [debouncedSave, draftKey, clearFunc]);
  const save = useCallback(dataToSave => {
    debouncedSave(dataToSave);
  }, [debouncedSave]);
  return [draft, save, clear];
}
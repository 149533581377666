import { registerQuery, useQuery } from 'data-fetching-client';
import { fetchCustomViewService } from './fetchCustomViewService';
const FETCH_HYDRATED_CUSTOM_VIEW = 'fetchHydratedCustomView';
export const fetchHydratedCustomView = registerQuery({
  args: ['customViewId'],
  fetcher: fetchCustomViewService,
  fieldName: FETCH_HYDRATED_CUSTOM_VIEW
});

/**
 * @description Fetches the hydrated custom view for the given `viewId`
 */

export const useHydratedCustomView = viewId => {
  const {
    data,
    networkStatus
  } = useQuery(fetchHydratedCustomView, {
    skip: !viewId,
    fetchPolicy: 'no-cache',
    variables: {
      customViewId: viewId ? +viewId : 0,
      convertToCustomView: false
    }
  });
  return {
    view: (data === null || data === void 0 ? void 0 : data.fetchHydratedCustomView) || {},
    status: networkStatus
  };
};
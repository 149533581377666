import { registerQuery } from 'data-fetching-client';
import { fetchRoutingTeams, fetchRoutingTeamsRpc } from '../api/teams';
const checkIsRpcProps = props => !!props.objectTypeId && typeof props.objectTypeId === 'string';
const checkIsFetchProps = props => !!props.workspaceId && typeof props.workspaceId === 'number';
const convertRpcUserToTeamUser = user => ({
  userId: user.userId,
  routableUser: user.isRoutableUser
});
const convertRpcTeamsToRoutingServerTeams = rpcTeams => rpcTeams.filter(({
  canTeamAccessInbox
}) => !!canTeamAccessInbox).map(team => {
  var _team$parentTeamId;
  return {
    childTeams: convertRpcTeamsToRoutingServerTeams(team.childTeams),
    id: team.teamId,
    name: team.name,
    numRoutableUsersInHierarchy: team.numRoutableUsersInHierarchy,
    parentTeamId: (_team$parentTeamId = team.parentTeamId) !== null && _team$parentTeamId !== void 0 ? _team$parentTeamId : null,
    users: team.users.map(convertRpcUserToTeamUser)
  };
});
export const SELECT_TEAMS_QUERY = registerQuery({
  fieldName: 'teams',
  args: ['objectTypeId', 'routingSource', 'workspaceId'],
  fetcher: async props => {
    if (checkIsRpcProps(props)) {
      const rpcResponse = await fetchRoutingTeamsRpc(props);
      return convertRpcTeamsToRoutingServerTeams(rpcResponse.routableTeams);
    }
    if (checkIsFetchProps(props)) {
      return fetchRoutingTeams(props.workspaceId);
    }
    throw new Error('Cannot fetch teams without a workspaceId or objectTypeId');
  }
});
'use es6';

import get from 'transmute/get';
import { CITATION } from '../constants/attachmentTypes';
import { getAttachments } from './getAttachments';
export const getCitations = message => {
  const attachments = getAttachments(message);
  if (!attachments) {
    return [];
  }
  const citationAttachments = attachments.filter(attachmentObject => get('@type', attachmentObject) === CITATION);
  return citationAttachments;
};
import { CALLING_EXTENSIONS_INBOUND_CALLING, CALLING_THIRD_PARTY_CHANNELS } from 'calling-internal-common/constants/CallingGates';
import { getSettingString } from './localSettingsOperators';
export const SUPPORTS_INBOUND_CALLING_KEY = 'supportsInboundCalling';
const SUPPORTS_INBOUND_CALLING_VALUE = 'true';
export const isExtensionsInboundCallingEnabledInLocalStorage = () => {
  return getSettingString(SUPPORTS_INBOUND_CALLING_KEY) === SUPPORTS_INBOUND_CALLING_VALUE;
};

/**
 * Toggles the calling extensions inbound calling experience.
 * @param gates
 * @returns boolean
 */
export const isExtensionsInboundCallingEnabled = gates => {
  const isUngated = gates && (gates.includes(CALLING_THIRD_PARTY_CHANNELS) || gates.includes(CALLING_EXTENSIONS_INBOUND_CALLING));
  return Boolean(isUngated || isExtensionsInboundCallingEnabledInLocalStorage());
};
import { useQuery } from 'data-fetching-client';
import Raven from 'raven-js';
import { SELECT_TEAMS_QUERY } from '../queries/teams.queries';
export const useTeams = ({
  objectTypeId,
  routingSource,
  skipFetch,
  workspaceId
}) => {
  const {
    data,
    error,
    loading
  } = useQuery(SELECT_TEAMS_QUERY, {
    onError: errorToTrack => {
      Raven.captureException(errorToTrack);
    },
    skip: skipFetch || !objectTypeId && !workspaceId,
    variables: {
      objectTypeId,
      routingSource,
      workspaceId
    }
  });
  return {
    error,
    loading,
    teams: data === null || data === void 0 ? void 0 : data.teams
  };
};
import { useState } from 'react';
const getInitialState = (key, initialValue) => {
  try {
    const value = window.localStorage.getItem(key) || initialValue;
    window.localStorage.setItem(key, value);
    return value;
  } catch (error) {
    return initialValue;
  }
};
export const useLocalStorage = ({
  key,
  initialValue
}) => {
  const [item, setValue] = useState(() => getInitialState(key, initialValue));
  const setItem = value => {
    setValue(value);
    try {
      window.localStorage.setItem(key, value);
    } catch (error) {
      // do nothing
    }
  };
  const removeItem = () => {
    setValue(null);
    try {
      window.localStorage.removeItem(key);
    } catch (error) {
      //do nothing
    }
  };
  return {
    item,
    setItem,
    removeItem
  };
};
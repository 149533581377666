import { registerMutation, useMutation } from 'data-fetching-client';
import { deleteSpaceClient } from '../clients/deleteSpaceClient';
import { fetchSpaces } from '../operations/fetchSpaces';
import { fetchSpace } from './useSpace';
const DELETE_SPACE = 'deleteSpace';
const deleteSpaceMutation = registerMutation({
  fieldName: DELETE_SPACE,
  fetcher: deleteSpaceClient
});
export function useDeleteSpace() {
  return useMutation(deleteSpaceMutation, {
    //Refetch spaces after deleting a space
    refetchQueries: [fetchSpace, fetchSpaces]
  });
}
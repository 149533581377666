import { registerMutation, registerQuery, useMutation, useQuery } from 'data-fetching-client';
import http from 'hub-http/clients/apiClient';
import { SUBSCRIPTION_SETTINGS_TIP_ID } from '../../utils/constants';
export const TIP_DISMISSED_QUERY = registerQuery({
  fieldName: 'tip',
  args: ['tipId'],
  fetcher: ({
    tipId
  }) => {
    return http.get(`/coaching-tips/v1/status/${tipId}`);
  }
});
export const DISMISS_TIP = registerMutation({
  fieldName: 'dismissTipResponse',
  args: ['tipId'],
  fetcher: ({
    tipId
  }) => {
    return http.post(`/coaching-tips/v1/status`, {
      data: {
        coachingTipId: tipId
      }
    });
  }
});
export function useHasDismissedSubscriptionSettingsTip() {
  var _data$tip$dismissed, _data$tip;
  const {
    data
  } = useQuery(TIP_DISMISSED_QUERY, {
    variables: {
      tipId: SUBSCRIPTION_SETTINGS_TIP_ID
    }
  });
  return (_data$tip$dismissed = data === null || data === void 0 || (_data$tip = data.tip) === null || _data$tip === void 0 ? void 0 : _data$tip.dismissed) !== null && _data$tip$dismissed !== void 0 ? _data$tip$dismissed : true;
}
export function useDismissSubscriptionSettingsTip() {
  return useMutation(DISMISS_TIP, {
    variables: {
      tipId: SUBSCRIPTION_SETTINGS_TIP_ID
    }
  });
}
export const OPEN_WIDGET = 'openWidget';
export const SET_CAPABILITIES = 'setCapabilities';
export const GET_CAPABILITIES = 'getCapabilities';
export const PRELOAD = 'preload';
export const SET_CALL_METHOD = 'setCallMethod';
export const UPDATE_READY_STATE = 'updateReadyState';
export const PAGE_NAVIGATION = 'pageNavigation';
export const HIDE_WIDGET = 'hideWidget';
export const UPDATE_THREAD_ID = 'updateThreadId';
export const SET_CALLING_PROVIDER_SETTING = 'setCallingProviderSetting';
export const UPDATE_CALLEE_NUMBER = 'updateCalleeNumber';
export const UPDATE_IS_QUEUE_TASK = 'updateIsQueueTask';
export const MINIMIZE_CALLING_WIDGET = 'minimizeCallingWidget';
export const ADD_EVENT_LISTENER = 'addEventListener';
export const REMOVE_EVENT_LISTENER = 'removeEventListener';
export const TRIGGER_EVENT_LISTENER = 'triggerEvent';
export const ADD_CALLABLE_ASSOCIATION = 'addCallableAssociation';
export const REMOVE_CALLABLE_ASSOCIATION = 'removeCallableAssociation';
export const SET_BROWSER_HISTORY = 'setBrowserHistory';
export const START_CALL = 'startCall';
export const ADD_PHONE_NUMBER = 'addPhoneNumber';
export const REGISTER_FROM_NUMBER = 'registerFromNumber';
export const LEARN_MORE = 'learnMore';
export const OPEN_THIRD_PARTY_WIDGET = 'openThirdPartyWidget';
export const HIDE_THIRD_PARTY_WIDGET = 'hideThirdPartyWidget';
export const UPDATE_THIRD_PARTY_SUBJECT = 'updateThirdPartySubject';
export const ON_CALL_SAVED_THIRD_PARTY = 'onCallSavedThirdParty';
export const CLOSE_THIRD_PARTY_DEMO_EXIT_CONFIRMATION = 'closeThirdPartyDemoExitConfirmation';
export const OPEN_PROVIDER_MENU = 'openProviderMenu';
import UIIconButton from 'UIComponents/button/UIIconButton';
import { MAGENTA, BLACK, WHITE } from '../../../../styles/colors';
import styled, { css } from 'styled-components';
import { SLINKY } from 'HubStyleTokens/colors';
const getSizeShortHand = size => {
  switch (size) {
    case 'extra-small':
      return 'xs';
    case 'small':
      return 'sm';
    case 'medium':
      return 'md';
    default:
      return size || 'md';
  }
};
const CssBaseIcon = css(["position:relative;display:block;border-radius:2rem !important;border:none !important;margin:0 !important;padding:0 !important;line-height:0 !important;& *{line-height:0 !important;}"]);
export const getComputedFontSize = size => {
  const shorthandSize = getSizeShortHand(size);
  switch (shorthandSize) {
    case 'xs':
      return 24;
    case 'sm':
      return 32;
    case 'md':
      return 40;
    default:
      return 40;
  }
};
const getIconFontSize = size => {
  const sizePx = getComputedFontSize(size);
  if (size) {
    return css(["font-size:calc(", "px - 8px) !important;width:", "px !important;height:", "px !important;& > span{position:absolute;left:0;top:0;width:100%;height:100%;& > span{position:absolute;left:0;top:50%;width:100%;height:100%;}}> span[data-icon-name='breezeRegenerate'] > span{top:calc(50% - 1px);font-size:calc(", "px - 7px) !important;}"], sizePx, sizePx, sizePx, sizePx);
  }
};
export const BreezeTransparentAIBaseIconButton = styled(UIIconButton).withConfig({
  displayName: "StyledAIBaseIconButton__BreezeTransparentAIBaseIconButton",
  componentId: "vptpvh-0"
})(["", " ", " &:not(.disabled){color:", " !important;}&:hover{color:", " !important;}&:active{color:", " !important;}"], CssBaseIcon, ({
  size
}) => getIconFontSize(size), BLACK['NEUTRAL'], MAGENTA[400], SLINKY);
export const BreezeLinkOnDarkAIBaseIconButton = styled(UIIconButton).withConfig({
  displayName: "StyledAIBaseIconButton__BreezeLinkOnDarkAIBaseIconButton",
  componentId: "vptpvh-1"
})(["", " ", " &:not(.disabled){color:", " !important;}&:hover{color:", " !important;}&:active{color:", " !important;}"], CssBaseIcon, ({
  size
}) => getIconFontSize(size), WHITE, MAGENTA[300], MAGENTA[100]);
export const getStyledAIIconButton = use => {
  switch (use) {
    case 'transparent':
      return BreezeTransparentAIBaseIconButton;
    case 'link':
      return BreezeTransparentAIBaseIconButton;
    case 'link-on-dark':
      return BreezeLinkOnDarkAIBaseIconButton;
    default:
      return BreezeTransparentAIBaseIconButton;
  }
};
import tracker from '../usageTracker';
class ZorseCrmComponentsTracker {
  clickZorseTicket() {
    tracker.track('zorseCrmComponentsInteraction', {
      action: 'click-zorse-ticket'
    });
  }
  clickSourceUrl() {
    tracker.track('zorseCrmComponentsInteraction', {
      action: 'click-source-url'
    });
  }
  clickHubId() {
    tracker.track('zorseCrmComponentsInteraction', {
      action: 'click-hub-id'
    });
  }
  clickHubDetails() {
    tracker.track('zorseCrmComponentsInteraction', {
      action: 'click-hub-details'
    });
  }
  clickBillingAdmin() {
    tracker.track('zorseCrmComponentsInteraction', {
      action: 'click-billing-admin'
    });
  }
  clickSupportToolbox() {
    tracker.track('zorseCrmComponentsInteraction', {
      action: 'click-support-toolbox'
    });
  }
  clickEmailSupportTools() {
    tracker.track('zorseCrmComponentsInteraction', {
      action: 'click-email-support-tools'
    });
  }
  clickSqlLink() {
    tracker.track('zorseCrmComponentsInteraction', {
      action: 'click-sql-link'
    });
  }
  clickKBFeedbackFormLink() {
    tracker.track('zorseCrmComponentsInteraction', {
      action: 'click-kb-feedback-form-link'
    });
  }
  openPanel() {
    tracker.track('realtimeRepsConversationPropertiesInteraction', {
      action: 'open-panel'
    });
  }
  closePanel() {
    tracker.track('realtimeRepsConversationPropertiesInteraction', {
      action: 'close-panel'
    });
  }
  cancelClicked() {
    tracker.track('realtimeRepsConversationPropertiesInteraction', {
      action: 'cancel-clicked'
    });
  }
  updateThreadProperty(threadId, propertyName, propertyValue) {
    tracker.track('realtimeRepsConversationPropertiesUsage', {
      action: 'conversation-property-updated',
      thread_id: threadId,
      property_name: propertyName,
      property_value: propertyValue
    });
  }
  markThreadAsGhosted(threadId, ghosted) {
    tracker.track('realtimeRepsConversationPropertiesUsage', {
      action: 'ghosted-checked',
      thread_id: threadId,
      ghosted
    });
  }
  resolveConversation(threadId, threadRecord) {
    tracker.track('realtimeRepsConversationPropertiesUsage', {
      action: 'resolve-conversation',
      thread_id: threadId,
      properties: JSON.stringify(threadRecord.properties.map(prop => prop.value).toJS()),
      ghosted: threadRecord.ghosted
    });
  }
}
export default new ZorseCrmComponentsTracker();
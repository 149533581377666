import { SPAM_THREAD_LIST_ID } from 'find-and-filter-data/view-members-data/constants';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSpamThreadList, selectSpamThreadListState } from './spamThreadListSlice';
let isPending = false;
const getSpamThreadList = data => data[SPAM_THREAD_LIST_ID];
export const useSpamThreadList = ({
  skip
} = {}) => {
  const dispatch = useDispatch();
  const {
    data,
    state
  } = useSelector(selectSpamThreadListState);
  const spamThreadList = getSpamThreadList(data);
  useEffect(() => {
    if (!isPending && !skip && !spamThreadList) {
      isPending = true;
      dispatch(fetchSpamThreadList()).finally(() => {
        isPending = false;
      });
    }
    return () => {
      isPending = false;
    };
  }, [dispatch, skip, spamThreadList]);
  return {
    threadList: spamThreadList,
    state
  };
};
// eslint-disable-next-line conversations/no-private-internals-import
import { fetchUpdatedViewsService } from '../../../views-data/_internal/views/fetchUpdatedViewsService';
import { fetchSpacesAndViews } from '../hooks/useSpacesAndViews';
export async function fetchUpdatedViewCounts({
  client,
  viewIds,
  currentCustomViewId,
  quickFilters
}) {
  // Get the existing cached data
  const cachedData = client.cache.readQuery({
    query: fetchSpacesAndViews
  });
  if (!(cachedData !== null && cachedData !== void 0 && cachedData.fetchSpacesAndViews)) return {
    currentViewTableCount: 0
  };

  // Fetch the updated counts
  const {
    customViewIdAndCountsList,
    currentViewTableCount
  } = await fetchUpdatedViewsService({
    currentCustomViewId,
    quickFilters,
    viewIds
  });

  // Update counts in all view arrays (globalViews, preSpaceViews, spaceViews)
  const updateViewCounts = views => {
    return views.map(view => {
      const viewToBeUpdated = customViewIdAndCountsList.find(viewUpdate => viewUpdate.customViewId === view.customViewId);
      if (!viewToBeUpdated) return view;
      return Object.assign({}, view, {
        threadCount: viewToBeUpdated.totalCount,
        unreadThreadCount: viewToBeUpdated.unreadCount
      });
    });
  };

  // Write the updated data back to cache
  client.cache.updateQuery({
    query: fetchSpacesAndViews
  }, existingData => {
    if (!(existingData !== null && existingData !== void 0 && existingData.fetchSpacesAndViews)) return existingData;
    return {
      fetchSpacesAndViews: Object.assign({}, existingData.fetchSpacesAndViews, {
        globalViews: updateViewCounts(existingData.fetchSpacesAndViews.globalViews),
        preSpaceViews: updateViewCounts(existingData.fetchSpacesAndViews.preSpaceViews),
        spaceViews: updateViewCounts(existingData.fetchSpacesAndViews.spaceViews)
      })
    };
  });
  return {
    currentViewTableCount
  };
}
'use es6';

import UIImage from 'UIComponents/image/UIImage';
import UIClickable from 'UIComponents/button/UIClickable';
import Big from 'UIComponents/elements/Big';
import { BUTTON_RADIUS, BUTTON_BORDER_WIDTH } from 'HubStyleTokens/sizes';
import { SELECTABLE_BOX_SHADOW_COLOR, SELECTABLE_BOX_BORDER_COLOR } from 'HubStyleTokens/theme';
import styled from 'styled-components';
export const ConnectProviderButtonImage = styled(UIImage).attrs({
  responsive: false
}).withConfig({
  displayName: "ConnectProviderButton__ConnectProviderButtonImage",
  componentId: "sc-10la43l-0"
})(["height:50px;"]);
export const ConnectProviderButtonTitle = styled(Big).withConfig({
  displayName: "ConnectProviderButton__ConnectProviderButtonTitle",
  componentId: "sc-10la43l-1"
})([""]);
export const ConnectProviderButton = styled(UIClickable).withConfig({
  displayName: "ConnectProviderButton",
  componentId: "sc-10la43l-2"
})(["border-color:", ";border-radius:", ";border-width:", ";border-style:solid;padding:12px 24px;flex:1 0 0px;display:flex;flex-direction:column;", "{flex:0 0 auto;align-self:center;}", "{flex:1 0 auto;margin-top:12px;display:flex;flex-direction:column;justify-content:center;}&:hover{box-shadow:0 0 0 1px ", ",0 0 12px 0 ", ";}"], SELECTABLE_BOX_BORDER_COLOR, BUTTON_RADIUS, BUTTON_BORDER_WIDTH, ConnectProviderButtonImage, ConnectProviderButtonTitle, SELECTABLE_BOX_SHADOW_COLOR, SELECTABLE_BOX_SHADOW_COLOR);
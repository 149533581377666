import { Set as ImmutableSet } from 'immutable';
import styled from 'styled-components';
import { LAST } from 'conversations-message-history/constants/messagePositionDescriptors';
/**
 * @description Manages inter/intra message spacing based on
 * position descriptors, adding more space between message
 * groups than between individual messages
 */
export default styled.div.attrs({
  'data-test-id': 'spacing-wrapper'
}).withConfig({
  displayName: "SpacingWrapper",
  componentId: "zvu6ul-0"
})(["padding-top:8px;padding-bottom:", ";padding-left:10px;padding-right:10px;width:100%;@media screen and (max-width:900px){padding-left:0;padding-right:0;}"], ({
  positionDescriptors = ImmutableSet()
}) => positionDescriptors.contains(LAST) ? '8px' : '4px');
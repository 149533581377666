import { make, equals } from 'foundations-components/transitional/utils/SimpleDate';
import { isNone } from './common';
const NUMERIC = /^\d+$/;
export const ONE_HOUR_IN_MILLISECONDS = 1000 * 60 * 60;
export const ONE_DAY_IN_MILLISECONDS = ONE_HOUR_IN_MILLISECONDS * 24;
export const ONE_WEEK_IN_MILLISECONDS = ONE_DAY_IN_MILLISECONDS * 7;

/**
 * Returns a number parsed from a timestamp string in UTC milliseconds (all HS services return timestamps in UTC milliseconds)
 * @param timestamp
 * @returns number or null
 * @example
 * const timestamp = '1593648000000';
 * const date = parseDate(timestamp);
 * console.log(date); 1593648000000
 * const nothing = parseDate(null);
 * console.log(nothing); null
 */
export function parseDate(timestamp) {
  if (isNone(timestamp)) {
    return null;
  }
  if (!NUMERIC.test(timestamp)) {
    console.error(`Invalid timestamp: ${timestamp}`);
    return null;
  }
  return parseInt(timestamp, 10);
}

/**
 * Returns a new timestamp with the timezone offset removed (in UTC)
 * @returns number
 * */
export function getUTCDate(now = new Date()) {
  return Math.floor(now.getTime() / 1000) * 1000;
}

/**
 * Returns true if the difference between now UTC and the given (UTC) timestamp is less than the given number of hours
 * @param comparisonDate number
 * @returns boolean
 * @example
 * const oneDayOut = getUTCDate() + 1000 * 60 * 60 * 24; // now + 1 day
 * isWithinHours(oneDayOut, 48); // true
 *
 * const twoDaysOut = getUTCDate() + 1000 * 60 * 60 * 48; // now + 2 days
 * isWithinHours(twoDaysOut, 48); // true
 * const twoDaysOneHourOut = getUTCDate() + 1000 * 60 * 60 * 49; // now + 2 days, 1 hour
 * isWithinHours(twoDaysOneHourOut, 48); // false
 */
export function isWithinHours(comparisonDate, hours) {
  const difference = Math.abs(getUTCDate() - comparisonDate);
  return difference / ONE_HOUR_IN_MILLISECONDS <= hours;
}
export function isInTheFuture(timestamp) {
  return timestamp > getUTCDate();
}
export function isInThePast(timestamp) {
  return timestamp < getUTCDate();
}
export function getIsNextPaymentDateToday(nextPaymentDueDate) {
  if (isNone(nextPaymentDueDate)) {
    return false;
  }
  // there are instances when today is the next payment date but the payment time has not passed, so we want to
  // have this as a safe guard for the hours before the payment
  const currentSimpleDate = createSimpleDate(Date.now());
  const nextPaymentDateSimpleDate = createSimpleDate(nextPaymentDueDate);
  return equals(currentSimpleDate, nextPaymentDateSimpleDate);
}

/**
 * Returns a SimpleDate from a timestamp
 * @param timestamp number
 * @returns SimpleDate
 *
 */
export function createSimpleDate(timestamp) {
  const date = new Date(timestamp);
  return make(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
}

/**
 * Returns the amount of days between two timestamps
 * @param t1 number
 * @param t2 number
 * @returns number
 *
 */
export function getDateDifference(t1, t2) {
  return Math.floor((t2 - t1) / ONE_DAY_IN_MILLISECONDS);
}
export function getDateIntervalDifference(proposedDate, lastPaymentDate) {
  return proposedDate - lastPaymentDate;
}
import { useCallback } from 'react';
import enviro from 'enviro';
import { useSendOnCopilotChannel } from '../channel/hooks/useSendOnCopilotChannel';
import { parseInAppCopilotAction } from '../actions/parseInAppCopilotAction';
import { consoleIfNotProd } from 'chatspot-core/loggers/consoleIfNotProd';
export const useSendCopilotAction = () => {
  const sendChatspotActionOnChannel = useSendOnCopilotChannel('SEND_ACTION');
  return useCallback(({
    messageAction: inAppMessageAction,
    messageMetaInfo: inAppMessageMetaInfo
  }) => {
    const messageMetaInfo = Object.assign({}, inAppMessageMetaInfo, {
      source: 'ACTION',
      type: 'IN-APP'
    });
    const messageAction = parseInAppCopilotAction(inAppMessageAction);
    if (messageAction) {
      sendChatspotActionOnChannel({
        messageAction,
        messageMetaInfo
      });
    } else {
      if (!enviro.deployed()) {
        consoleIfNotProd.warn(`Could not parse in-app copilot action ${messageAction}`);
      }
    }
  }, [sendChatspotActionOnChannel]);
};
// @ts-expect-error dependency missing types
import { catchAndRethrowNetworkError } from 'crm_data/bCommerce/monitoring/errorUtils';
import { registerMutation } from 'data-fetching-client';
import apiClient from 'hub-http/clients/apiClient';
// API docs: https://tools.hubteam.com/api-catalog/services/PaymentsSubscriptionsService/v1/spec/internal#operations-Subscriptions-post-%2Fpayments-subscriptions%2Fv1%2Fsubscriptions%2Fcrm%2F%7BobjectId%7D%2Fpause
export function pauseSubscription({
  objectId,
  pauseReason
}) {
  return apiClient.post(`payments-subscriptions/v1/subscriptions/crm/${objectId}/pause`, {
    data: {
      pauseReason
    }
  }).catch(catchAndRethrowNetworkError);
}
export const PAUSE_SUBSCRIPTION = registerMutation({
  fieldName: 'pauseResponse',
  args: ['objectId', 'pauseReason'],
  fetcher: pauseSubscription
});
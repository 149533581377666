// @ts-expect-error untyped dependency
import Sanitize from 'sanitize';

// this is used because the document doesn't try to resolve images or execute scripts
// https://stackoverflow.com/a/11530238
const secureDocument = document.implementation.createHTMLDocument('sanitize');
const element = secureDocument.body;
const QUOTE_EDITOR_CONFIG = {
  elements: ['a', 'b', 'blockquote', 'br', 'em', 'i', 'li', 'ol', 'p', 'strong', 'u', 'ul', 'div', 'span'],
  attributes: {
    a: ['href', 'title', 'target'],
    div: ['style'],
    ol: ['type']
  },
  add_attributes: {
    a: {
      rel: 'nofollow noopener noreferrer'
    }
  },
  dom: secureDocument,
  protocols: {
    a: {
      href: ['ftp', 'http', 'https', 'mailto', Sanitize.RELATIVE]
    },
    div: {
      style: ['text-align']
    }
  },
  remove_contents: ['style', 'script']
};
export default (body => {
  if (!body) {
    return '';
  }
  const sanitizer = new Sanitize(QUOTE_EDITOR_CONFIG);
  try {
    secureDocument.domain = window.location.hostname.indexOf('qa') >= 0 ? 'hubspotqa.com' : 'hubspot.com';
  } catch (e) {
    // IE11 needs to have this but it throws an error in Chrome.
  }
  element.innerHTML = body;
  // For specific documentation on the Sanitize.js library, config, or transformers, see: https://github.com/gbirke/Sanitize.js
  const fragment = sanitizer.clean_node(element).cloneNode(true);
  const container = secureDocument.createElement('div');
  container.appendChild(fragment);
  return container.innerHTML;
});
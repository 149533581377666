import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["options", "viewId"];
import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchThreadListMembersService } from './thread-list-member/fetchThreadListMembersService';
import { fetchSpamViewMembersService } from './view-member-page/fetchSpamViewMembersService';
export const fetchViewMembers = createAsyncThunk('viewMembers/fetchViewMembers', ({
  inboxId,
  limit,
  offsetId,
  offsetTimestamp,
  options,
  sortDirection,
  threadStatus,
  viewId
}) => {
  void options;
  return fetchThreadListMembersService({
    inboxId,
    limit,
    offsetId,
    offsetTimestamp,
    sortDirection,
    threadStatus,
    viewId
  });
});
export const fetchSpamViewMembers = createAsyncThunk('viewMembers/fetchSpamViewMembers', _ref => {
  let {
      options,
      viewId
    } = _ref,
    rest = _objectWithoutPropertiesLoose(_ref, _excluded);
  void options;
  void viewId;
  return fetchSpamViewMembersService(Object.assign({}, rest));
});
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CIRCLE_BASE_SIZE } from '../constants/baseValues';
import { getSize } from '../operators/getSize';
import SkeletonBase from './SkeletonBase';
// Default props
const DEFAULT_SIZE = CIRCLE_BASE_SIZE;
const SkeletonCircle = styled(SkeletonBase).withConfig({
  displayName: "SkeletonCircle",
  componentId: "sc-1qgifmy-0"
})(["border-radius:50%;width:", ";height:", ";min-width:", ";&::before{border-radius:50%;}"], ({
  size = DEFAULT_SIZE
}) => getSize(size), ({
  size = DEFAULT_SIZE
}) => getSize(size), ({
  size = DEFAULT_SIZE
}) => getSize(size));
SkeletonCircle.displayName = 'SkeletonCircle';
SkeletonCircle.propTypes = {
  size: PropTypes.number
};
export default SkeletonCircle;
import PortalIdParser from 'PortalIdParser';

/**
 * HubSpot defined properties that are required for each object type. Allows consumers to forgo an all properties
 * request if their app is data intensive and only needs a subset of properties.
 */
const HUBSPOT_EAGLERLY_FETCH_PROPERTY_NAMES = {
  ['0-1']: ['firstname', 'lastname', 'email', 'hs_additional_emails', 'hs_additional_domains',
  // both of these analytics keys are used to display each other
  // see: https://git.hubteam.com/HubSpot/CRM/pull/24039/files#r1167989
  'hs_analytics_source', 'hs_analytics_source_data_1', 'hs_latest_source', 'hs_latest_source_data_1', 'hs_object_source_id', 'objectType', 'hubspot_owner_id', 'hs_all_owner_ids', 'hubspot_team_id', 'hs_all_team_ids', 'hs_all_accessible_team_ids', 'createdate', 'hs_lastactivitydate', 'notes_last_created', 'notes_last_updated',
  // https://git.hubteam.com/HubSpot/customer-data-properties/blob/master/customer-data-properties/static/js/v2/components/FormattedPropertyV2.tsx#L420
  'hs_marketable_reason_type'],
  ['0-2']: ['name', 'domain',
  // domain often requires hs_additional_domains to render all domains
  'hs_additional_domains', 'phone', 'website', 'hubspot_owner_id', 'hs_all_owner_ids', 'hubspot_team_id', 'hs_all_team_ids', 'hs_all_accessible_team_ids', 'hs_annual_revenue_currency_code', 'createdate', 'hs_lastactivitydate', 'notes_last_created', 'notes_last_updated',
  // both of these analytics keys are used to display each other
  // see: https://git.hubteam.com/HubSpot/CRM/pull/24039/files#r1167989
  'hs_analytics_source', 'hs_analytics_source_data_1', 'hs_latest_source', 'hs_latest_source_data_1'],
  ['0-3']: [
  // Adapted from DealBoardSearchAPI's list of included properties:
  'dealname', 'amount', 'amount_in_home_currency', 'description', 'pipeline', 'closedate', 'createdate', 'deal_currency_code', 'dealtype', 'dealstage', 'relatesTo', 'hubspot_owner_id', 'hs_all_owner_ids', 'hs_priority', 'hubspot_team_id', 'hs_all_team_ids', 'hs_all_accessible_team_ids', 'hs_lastactivitydate', 'hs_latest_approval_status', 'notes_last_created', 'notes_last_updated', 'hs_num_associated_deal_splits', 'notes_next_activity_date', 'notes_last_updated',
  // both of these analytics keys are used to display each other
  // see: https://git.hubteam.com/HubSpot/CRM/pull/24039/files#r1167989
  'hs_analytics_source', 'hs_analytics_source_data_1', 'hs_latest_source', 'hs_latest_source_data_1', 'hs_is_closed', 'hs_projected_amount', 'hs_projected_amount_in_home_currency'],
  ['0-5']: [
  // Adapted from TicketBoardSearchAPI's list of included properties:
  'closed_date', 'content', 'createdate', 'hs_ticket_priority', 'hs_pipeline', 'hs_pipeline_stage', 'hubspot_owner_id', 'hs_all_owner_ids', 'hubspot_team_id', 'hs_all_team_ids', 'hs_all_accessible_team_ids', 'subject', 'hs_lastactivitydate', 'hs_nextactivitydate', 'notes_last_created', 'notes_next_activity_date', 'notes_last_updated'
  // NOTE: analytics properties are not set on tickets, only contacts/companies/deals
  // See https://product.hubteam.com/docs/crm-properties/values/analytics_sources.html
  ],
  ['0-7']: ['hs_recurring_billing_period', 'recurringbillingfrequency'],
  ['0-53']: ['hs_currency', 'hs_due_date', 'hs_invoice_source', 'hs_invoice_status', 'hs_payment_date'],
  ['0-69']: ['hs_currency_code', 'hs_invoice_creation'],
  ['0-101']: ['hs_currency_code']
};
const PORTAL_SPECIFIC_REQUIRED_PROPERTIES = {
  53: {
    '2-5945472': ['currency_53']
  },
  99535353: {
    '2-5868076': ['currency_53']
  }
};
export const getEagerlyFetchPropertyList = objectTypeId => {
  var _HUBSPOT_EAGLERLY_FET, _PORTAL_SPECIFIC_REQU, _PORTAL_SPECIFIC_REQU2;
  const hubSpotEagerlyFetchPropertyNamesForObjectType = (_HUBSPOT_EAGLERLY_FET = HUBSPOT_EAGLERLY_FETCH_PROPERTY_NAMES[objectTypeId]) !== null && _HUBSPOT_EAGLERLY_FET !== void 0 ? _HUBSPOT_EAGLERLY_FET : [];
  const propertyNames = new Set([...hubSpotEagerlyFetchPropertyNamesForObjectType, ...((_PORTAL_SPECIFIC_REQU = (_PORTAL_SPECIFIC_REQU2 = PORTAL_SPECIFIC_REQUIRED_PROPERTIES[PortalIdParser.get()]) === null || _PORTAL_SPECIFIC_REQU2 === void 0 ? void 0 : _PORTAL_SPECIFIC_REQU2[objectTypeId]) !== null && _PORTAL_SPECIFIC_REQU !== void 0 ? _PORTAL_SPECIFIC_REQU : [])]);
  return Array.from(propertyNames);
};
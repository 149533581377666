import { aggregateValidatorResults } from './result';

/**
 * Validate a node and store the result in a shared map. Note that this map acts as an accumulator
 * that is passed in by the caller and mutated. We do this for simplicity, since alternatives
 * would require more complex logic to handle the accumulation of results.
 */
export const validateNode = (node, request, validators, validationMap) => {
  // Some nodes depend on the validation of their children, so we need to validate them first.
  const childResults = node.dependents.map(child => validateNode(child, request, validators, validationMap));
  const nodeResults = validators.map(validator => validator.validate(node, request));
  const result = aggregateValidatorResults([...childResults, ...nodeResults]);
  validationMap.set(node.nodeId, result);
  return result;
};
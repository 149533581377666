import formatPhoneNumber from 'I18n/utils/formatPhoneNumber';
// @ts-expect-error Untyped dependency
import formatPhoneNumberWithExtension from 'I18n/utils/formatPhoneNumberWithExtension';
// @ts-expect-error Untyped dependency
import * as TelephoneData from 'I18n/constants/TelephoneData';
import guessCountryFromDialCode from 'I18n/utils/guessCountryFromDialCode';
const UTF_16_A = 65;
const UTF_16_REGION_A = 127462;
const REGION_START = UTF_16_REGION_A - UTF_16_A;

/**
 * Returns the corresponding country dial code.
 *
 * @example
 * getCountryDialCode('us'); // => '1'
 * getCountryDialCode('gb'); // => '44'
 * getCountryDialCode('unknown'); // => undefined
 */
export function getCountryDialCode(country) {
  if (!country) {
    return undefined;
  }
  const iso2 = TelephoneData.iso2Lookup[country];
  const dialCode = TelephoneData.allCountries[iso2].dialCode;
  return dialCode;
}
export function parseRawPhoneNumber(rawPhoneNumber) {
  const regex = new RegExp(/^(.*) ext\D*(\d+)$/);
  const phoneNumberParts = regex.exec(rawPhoneNumber);
  const phoneNumber = phoneNumberParts ? phoneNumberParts[1] : rawPhoneNumber;
  const extension = phoneNumberParts ? phoneNumberParts[2] : '';
  return {
    phoneNumber,
    extension
  };
}
export function isValidRawPhoneNumber(rawPhoneNumber) {
  const {
    phoneNumber,
    extension
  } = parseRawPhoneNumber(rawPhoneNumber);
  const regex = /(?=[^+])\D/; // match non-digits excluding '+' character

  return !regex.test(phoneNumber) && !regex.test(extension);
}
export function formatRawPhoneNumber(rawPhoneNumber) {
  if (isValidRawPhoneNumber(rawPhoneNumber)) {
    const {
      phoneNumber,
      extension
    } = parseRawPhoneNumber(rawPhoneNumber);
    return extension ? formatPhoneNumberWithExtension(phoneNumber, extension) : formatPhoneNumber(phoneNumber, '');
  }
  return rawPhoneNumber;
}
export function removeNonNumberCharacters(value) {
  return value.replace(/[^\d]/g, '');
}
export function removeInvalidPhoneNumberCharacters(value) {
  if (value.startsWith('+')) {
    return `+${removeNonNumberCharacters(value.slice(1))}`;
  }
  return removeNonNumberCharacters(value);
}
export function guessCountryFromPhoneNumber(phoneNumber) {
  if (!isValidRawPhoneNumber(phoneNumber)) {
    return '';
  }
  const cleanNumber = removeNonNumberCharacters(phoneNumber);
  const guess = guessCountryFromDialCode(cleanNumber);
  if (guess.dialCode !== cleanNumber.slice(0, guess.dialCode.length)) {
    return '';
  }
  return guess.iso2;
}

// This is a direct copy of https://git.hubteam.com/HubSpot/ui-addon-i18n/blob/master/static/js/components/CountryFlag.tsx#L12
export function getCountryFlagEmoji(country) {
  const unavailableCountryCodes = ['uk', 'an', 'ap'];
  if (unavailableCountryCodes.includes(country) || country === '') {
    return '';
  }
  const codePoints = country.toUpperCase().split('').map(char => REGION_START + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
}
export function addPlusIfNeeded(value) {
  return value.startsWith('+') ? value : `+${value}`;
}
export const applyFormatToPhoneNumber = (value, format) => {
  if (!value) {
    return value;
  }
  const numberText = (value || '').replace(/\D/g, '');
  if (numberText && numberText.length < 2 || !format) {
    return `+${numberText}`;
  }
  const formattedObject = {
    remainingText: numberText,
    formattedText: ''
  };
  for (const character of (format || '').split('')) {
    if (formattedObject.remainingText.length === 0) {
      break;
    }
    if (character !== '.') {
      formattedObject.formattedText = formattedObject.formattedText + character;
    } else {
      formattedObject.formattedText = formattedObject.formattedText + formattedObject.remainingText[0];
      formattedObject.remainingText = formattedObject.remainingText.slice(1);
    }
  }
  return formattedObject.formattedText + formattedObject.remainingText;
};
export const getPhoneNumberString = (phoneNumber, extension) => phoneNumber !== '' && extension !== null && extension !== '' && extension !== undefined ? `${phoneNumber} ext. ${extension}` : phoneNumber;
import { useMemo } from 'react';

// eslint-disable-next-line no-restricted-imports -- we use http by default
import http from 'hub-http/clients/apiClient';
import { EMAIL } from 'customer-data-objects/engagement/EngagementTypes';
import { useQuery } from 'data-fetching-client';
import { FETCH_NEVER_LOG_LIST } from '../api/NeverLogAPI';
export const getNeverLogDomainsAndAddresses = neverLogListData => {
  const portalBlocklistRecords = (neverLogListData === null || neverLogListData === void 0 ? void 0 : neverLogListData.portalBlocklistRecords) || [];
  const userBlocklistRecords = (neverLogListData === null || neverLogListData === void 0 ? void 0 : neverLogListData.userBlocklistRecords) || [];
  const neverLogList = portalBlocklistRecords.concat(userBlocklistRecords);
  const neverLogDomains = [];
  const neverLogAddresses = [];
  neverLogList.forEach(record => {
    if (record.type === 'DOMAIN') {
      neverLogDomains.push(record.value.toLowerCase());
    } else if (record.type === 'EMAIL') {
      neverLogAddresses.push(record.value.toLowerCase());
    }
  });
  return {
    neverLogDomains,
    neverLogAddresses
  };
};
export const useFetchNeverLogList = ({
  engagementOrInteractionType,
  apiClient = http,
  skip = false
}) => {
  // if there is an error, we will just ignore the never log list
  const {
    data,
    loading,
    refetch
  } = useQuery(FETCH_NEVER_LOG_LIST, {
    variables: {
      apiClient
    },
    skip: engagementOrInteractionType !== EMAIL || skip
  });
  const emptyNeverLogList = useMemo(() => ({
    neverLogDomains: [],
    neverLogAddresses: []
  }), []);
  const neverLogListData = useMemo(() => ({
    neverLogList: data ? getNeverLogDomainsAndAddresses(data.neverLogList) : emptyNeverLogList,
    neverLogListLoading: loading,
    refetch
  }), [emptyNeverLogList, data, loading, refetch]);
  if (!data) {
    return {
      neverLogList: emptyNeverLogList,
      neverLogListLoading: loading,
      refetch
    };
  }
  return neverLogListData;
};
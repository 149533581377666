import { HUBSPOT, TWILIO } from '../call-provider/constants/ProviderNames';
import { isExtensionsInboundCallingEnabled } from './isExtensionsInboundCallingEnabled';
import { isProviderSwitchingEnabled } from './isProviderSwitchingEnabled';
export const TWILIO_BASED_PROVIDERS = [TWILIO, HUBSPOT];
export const getIsEmptyCallProvider = callingProvider => {
  return !(callingProvider && callingProvider.get('name'));
};
export const getIsTwilioBasedCallProvider = callingProvider => {
  return TWILIO_BASED_PROVIDERS.includes(callingProvider && callingProvider.get('name'));
};
export const getIsThirdPartyCallingPopoverInUse = (gates, provider) => {
  if (getIsTwilioBasedCallProvider(provider) || getIsEmptyCallProvider(provider) || !isExtensionsInboundCallingEnabled(gates)) {
    return false;
  }
  return isProviderSwitchingEnabled(gates) ? !!provider.supportsInboundCalling : true;
};
// Revamped version of `useParseAutoAssociations` that also supports existing engagements

import { OrderedMap } from 'immutable';
import sortBy from 'transmute/sortBy';

// eslint-disable-next-line no-restricted-imports -- we use http by default
import http from 'hub-http/clients/apiClient';
import isPortalSpecificObjectType from 'customer-data-objects/crmObject/isPortalSpecificObjectType';

// @ts-expect-error untyped

import { AUTO_ASSOCIATIONS_HUBSPOT_DEFINED_OBJECT_TYPE_ID_ALLOWLIST, getAllAssociationRecords, getAllAutoAssociations, getParserData, parseUniversalEngagementAssociations } from '../helpers/parseUniversalEngagementAssociations';
import { enforceNeverLogList } from '../helpers/universalAssociationsSelectHelpers';
import { useFetchAutoAssociations } from './useFetchAutoAssociations';
import { useFetchNeverLogList } from './useFetchNeverLogList';
import { useFetchUniversalEngagementAssociations } from './useFetchUniversalEngagementAssociations';
export const useUniversalAssociations = ({
  apiClient = http,
  engagementId,
  engagementType,
  subjectObjectId,
  subjectObjectTypeId
}) => {
  const isSubjectObjectTypeSupportedForAutoAssociations = isPortalSpecificObjectType(subjectObjectTypeId) || AUTO_ASSOCIATIONS_HUBSPOT_DEFINED_OBJECT_TYPE_ID_ALLOWLIST.includes(subjectObjectTypeId || '');
  const autoAssociationsEnabled = Boolean(!engagementId && subjectObjectId && subjectObjectTypeId && isSubjectObjectTypeSupportedForAutoAssociations);
  const {
    data: associationsData,
    loading: associationsLoading,
    error: associationsError
  } = useFetchUniversalEngagementAssociations({
    engagementId,
    engagementOrInteractionType: engagementType,
    fetchPolicyOverride: 'cache-and-network',
    objectTypeId: subjectObjectTypeId || '',
    subjectId: subjectObjectId || ''
  });
  const {
    allAssociationTypesFromObjectType,
    engagement,
    subject
  } = associationsData || {};
  const {
    isExistingEngagement,
    associationTypeIdMap,
    associationRecordsFromDefinitions,
    engagementAssociationRecords
    // formats data needed for getAllAssociationRecords
  } = getParserData({
    allAssociationTypesFromObjectType,
    engagement
  });
  const {
    data: autoAssociationsData,
    loading: autoAssociationsLoading,
    error: autoAssociationsError
  } = useFetchAutoAssociations({
    subjectObjectTypeId,
    subjectObjectId,
    engagementType,
    apiClient,
    skip: !autoAssociationsEnabled
  });
  const {
    neverLogList,
    neverLogListLoading
  } = useFetchNeverLogList({
    engagementOrInteractionType: engagementType,
    apiClient,
    // if there's no subject record, there are no associations to never log
    skip: !subjectObjectTypeId || !subjectObjectId
  });

  // if there is a never log list error, we will just ignore the never log list
  const isError = autoAssociationsEnabled ? Boolean(associationsError || autoAssociationsError) : Boolean(associationsError);
  const isLoading = autoAssociationsEnabled ? associationsLoading || autoAssociationsLoading || neverLogListLoading : Boolean(associationsLoading || neverLogListLoading);

  // If missing subjectObjectTypeId, or loading/error
  if (!associationsData || isLoading || isError) {
    return {
      associationRecords: OrderedMap(),
      neverLogList: {
        neverLogDomains: [],
        neverLogAddresses: []
      },
      isLoading,
      isError
    };
  }

  // For existing engagements, unsupported subject object types, or new engagements missing subjectObjectId
  if (!autoAssociationsEnabled) {
    // TODO: Clean up how `parseUniversalEngagementAssociations` handles missing values
    // Issue: https://git.hubteam.com/HubSpot/CreateAndAssociateFrontendTeam/issues/492
    return {
      associationRecords: enforceNeverLogList({
        parsedAssociations: parseUniversalEngagementAssociations({
          data: associationsData,
          variables: {
            subjectObjectId,
            subjectObjectTypeId
          }
        }),
        neverLogList
      }),
      neverLogList,
      isLoading,
      isError
    };
  }

  // The combined result of the engagement associations,
  // the default associations, and the association records
  // from definitions.
  const allAssociations = getAllAssociationRecords(subject, subjectObjectId, subjectObjectTypeId, isExistingEngagement, associationRecordsFromDefinitions, engagementAssociationRecords, associationTypeIdMap, autoAssociationsData === null || autoAssociationsData === void 0 ? void 0 : autoAssociationsData.autoAssociationsData);

  // The combined result of the auto associations and
  // `allAssociations` with never log list enforcement
  const allAutoAssociations = enforceNeverLogList({
    // Parses `allAssociations` into expected shape for UniversalAssociationsSelect's `associationRecords` prop
    parsedAssociations: getAllAutoAssociations(allAssociations, autoAssociationsData === null || autoAssociationsData === void 0 ? void 0 : autoAssociationsData.autoAssociationsData),
    neverLogList
  });
  const associationRecords = sortBy(association => association.get('pluralObjectName'), allAutoAssociations);
  return {
    associationRecords,
    neverLogList,
    isLoading,
    isError
  };
};
import { useCallback, useMemo } from 'react';
import { isInvalidValidationResult } from '../types/result';
import { buildPropertiesFormInitializationFailedState, buildPropertiesFormInitializingState, buildPropertiesFormInvalidState, buildPropertiesFormPendingState, buildPropertiesFormValidState } from '../utils/status';
import { validateNode } from '../utils/validation';
import { conditionalOptionsValidator } from '../validators/conditionalOptionsValidator';
import { propertyStateValidator } from '../validators/propertyStateValidator';
import { requiredValueValidator } from '../validators/requiredValueValidator';
const initializingState = buildPropertiesFormInitializingState();
const failedInitializingState = buildPropertiesFormInitializationFailedState();
const emptyValidationsByNodeIdMap = new Map();
const validators = [requiredValueValidator, conditionalOptionsValidator, propertyStateValidator];
const createErrorMap = (validationResults, properties) => {
  // Group property names by error type as set to avoid duplicates,
  // else we risk multiple instances of a property in the form being counted for the same error.
  const errorToPropertyNames = validationResults.filter(isInvalidValidationResult).reduce((acc, {
    errorSources
  }) => {
    errorSources.forEach(({
      error,
      sourcePropertyName
    }) => {
      if (!acc.has(error)) {
        acc.set(error, new Set());
      }
      acc.get(error).add(sourcePropertyName);
    });
    return acc;
  }, new Map());
  return new Map(Array.from(errorToPropertyNames.entries()).map(([error, propertyNames]) => [error, Array.from(propertyNames).map(name => properties[name])]));
};
export function useFormValidation({
  activeRules,
  alwaysShowConditionalPropertyOptionsWithErrors,
  changedProperties,
  formLoading,
  formError,
  getFormValue,
  nodes,
  properties,
  propertyValidations
}) {
  const {
    validationState,
    validationsByNodeId
  } = useMemo(() => {
    if (formLoading) {
      return {
        validationState: initializingState,
        validationsByNodeId: emptyValidationsByNodeIdMap
      };
    }
    if (formError) {
      return {
        validationState: failedInitializingState,
        validationsByNodeId: emptyValidationsByNodeIdMap
      };
    }
    const request = {
      activeRules,
      alwaysShowConditionalPropertyOptionsWithErrors,
      changedProperties,
      getFormValue,
      properties,
      propertyValidations
    };
    const validationMap = new Map();
    const validationResults = nodes.map(node => validateNode(node, request, validators, validationMap));
    if (validationResults.some(result => result.status === 'PENDING')) {
      return {
        validationState: buildPropertiesFormPendingState(),
        validationsByNodeId: validationMap
      };
    }
    const errors = createErrorMap(validationResults, properties);
    if (errors.size > 0) {
      return {
        validationState: buildPropertiesFormInvalidState(errors),
        validationsByNodeId: validationMap
      };
    }
    return {
      validationState: buildPropertiesFormValidState(),
      validationsByNodeId: validationMap
    };
  }, [formLoading, formError, properties, propertyValidations, activeRules, changedProperties, alwaysShowConditionalPropertyOptionsWithErrors, getFormValue, nodes]);
  const getNodeValidation = useCallback(nodeId => {
    var _validationsByNodeId$;
    return (_validationsByNodeId$ = validationsByNodeId.get(nodeId)) !== null && _validationsByNodeId$ !== void 0 ? _validationsByNodeId$ : null;
  }, [validationsByNodeId]);
  return {
    validationState,
    getNodeValidation
  };
}
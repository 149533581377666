import { fetchSpacesAndViews } from '../hooks/useSpacesAndViews';
export const deleteViewFromCache = ({
  viewId,
  client
}) => {
  client.cache.updateQuery({
    query: fetchSpacesAndViews
  }, updateExistingData => {
    if (!(updateExistingData !== null && updateExistingData !== void 0 && updateExistingData.fetchSpacesAndViews)) return updateExistingData;

    //Delete the view from globalViews, preSpaceViews, and spaceViews
    const {
      globalViews,
      preSpaceViews,
      spaceViews
    } = updateExistingData.fetchSpacesAndViews;
    const updatedGlobalViews = globalViews.filter(view => `${view.customViewId}` !== viewId);
    const updatedPreSpaceViews = preSpaceViews.filter(view => `${view.customViewId}` !== viewId);
    const updatedSpaceViews = spaceViews.filter(view => `${view.customViewId}` !== viewId);
    return {
      fetchSpacesAndViews: Object.assign({}, updateExistingData.fetchSpacesAndViews, {
        globalViews: updatedGlobalViews,
        preSpaceViews: updatedPreSpaceViews,
        spaceViews: updatedSpaceViews
      })
    };
  });
};
import { useEffect, useMemo, useRef, useState } from 'react';
export function useModalControls({
  onClose
} = {}) {
  const onCloseRef = useRef(onClose);
  useEffect(() => {
    onCloseRef.current = onClose;
  });
  const [isOpen, setIsOpen] = useState(false);
  return useMemo(() => ({
    open: () => {
      if (isOpen) {
        return;
      }
      setIsOpen(true);
    },
    close: () => {
      var _onCloseRef$current;
      if (!isOpen) {
        return;
      }
      (_onCloseRef$current = onCloseRef.current) === null || _onCloseRef$current === void 0 || _onCloseRef$current.call(onCloseRef);
      setIsOpen(false);
    },
    isOpen
  }), [isOpen]);
}